import HeaderMainsite from './Header/HeaderMainsite';
import FooterMainsite from './Footer/FooterMainsite';
import { useState, useEffect } from 'react';

export const Layout = ({ children }: { children: any }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const didScrolled = winScroll / height;
      setScrolled(didScrolled > 0);
    };

    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  return (
    <div className={scrolled ? 'has-scroll' : ''}>
      <HeaderMainsite scrolled={scrolled} />
      {children}
      <FooterMainsite />
    </div>
  );
};
