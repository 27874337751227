import { ReferralType } from '../schemas';

export const referralEmails = {
  Default: {
    ToClientYouHaveBeenReferred: {
      id: 'referral-to-client-you-have-been-referred',
      name: 'To client "You have been referred to us"',
      types: [ReferralType.default],
    },
    ToClientReminderDoYouNeedHelp: {
      id: 'referral-to-client-reminder-do-you-need-help',
      name: 'To client "Reminder Do you need any help"',
      types: [ReferralType.default],
    },
    ToReferrerThankYouForReferral: {
      id: 'referral-to-referrer-thank-you-for-referral',
      name: 'To referrer "Thank you for your referral"',
      types: [ReferralType.default],
    },
    ToReferrerUnableToProceedWithReferral: {
      id: 'referral-to-referrer-unable-proceed-with-referral',
      name: 'To referrer "Unable to proceed with the referral"',
      types: [ReferralType.default],
    },
  },
  Shared: {
    // Both: Default && Healix
    ToReferrerAppointmentOutcome: {
      id: 'referral-to-referrer-appointment-outcome',
      name: 'To referrer "Appointment outcome"',
      types: [ReferralType.default, ReferralType.healix],
    },
    // Both: Healix and L&G
    ToClientYouHaveBeenReferred: {
      id: 'referral-to-client-partner-you-have-been-referred-v2',
      name: 'To client "Your referral"',
      types: [ReferralType.healix, ReferralType.landg],
    },
    ToClientBookReminder1: {
      id: 'referral-to-client-partner-reminder-to-book-1-v2',
      name: 'To client "Book your referral" - First reminder',
      types: [ReferralType.healix, ReferralType.landg],
    },
    ToClientBookReminder2: {
      id: 'referral-to-client-partner-reminder-to-book-2-v2',
      name: 'To client "Referral pending" - Second reminder',
      types: [ReferralType.healix, ReferralType.landg],
    },
  },
  Healix: {
    ToClientYouHaveBeenReferred: {
      id: 'referral-to-client-healix-you-have-been-referred',
      name: '(Old) To Healix client "You have been referred to us"',
      types: [ReferralType.healix],
    },
    ToReferrerThankYouForReferral: {
      id: 'referral-to-referrer-healix-thank-you-for-referral',
      name: 'To Healix referrer "We\'ve received your referral"',
      types: [ReferralType.healix],
    },
    ToClientReminderToBookAfterOneWeek: {
      id: 'referral-to-client-healix-reminder-to-book-after-1-week',
      name: '(Old) To Healix client "Book your Healix referral" Reminder after 1 week',
      types: [ReferralType.healix],
    },
    ToClientReminderToBookAfterTwoWeeks: {
      id: 'referral-to-client-healix-reminder-to-book-after-2-weeks',
      name: '(Old) To Healix client "Book your Healix referral" Reminder after 2 weeks',
      types: [ReferralType.healix],
    },
    ToClientNoBooking: {
      id: 'referral-to-client-healix-no-booking',
      name: 'To Healix client "Healix referral update" No booking',
      types: [ReferralType.healix],
    },
    ToReferrerUnableToProceed: {
      id: 'referral-to-referrer-healix-unable-to-proceed',
      name: 'To Healix referrer "Unable to proceed"',
      types: [ReferralType.healix],
    },
  },
  Landg: {
    ToClientYouHaveBeenReferred: {
      id: 'referral-to-client-landg-you-have-been-referred',
      name: '(Old) To L&G client "You have been referred to us"',
      types: [ReferralType.landg],
    },
    ToClientBookReminder1: {
      id: 'referral-to-client-landg-reminder-to-book-reminder-1',
      name: '(Old) To L&G client "Book your L&G referral" Reminder after 3 working days',
      types: [ReferralType.landg],
    },
    ToClientBookReminder2: {
      id: 'referral-to-client-landg-reminder-to-book-reminder-2',
      name: '(Old) To L&G client "Book your L&G referral" Reminder after 5 working days',
      types: [ReferralType.landg],
    },
  },
};

export const referralEmailIds = Object.values(referralEmails)
  .flatMap((v) => Object.values(v))
  .map((v) => v.id);
