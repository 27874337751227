export default function HeaderMainsite({ scrolled }: { scrolled?: boolean }) {
  return (
    <header
      id="top"
      className={`header js-sticky ${scrolled ? 'sticked' : ''}`}
      data-scrollheight="0"
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <a
              className="logo"
              href="https://www.percihealth.com"
              title="Perci Health provides on-demand access to high-quality cancer experts who are focused on the side effects of treatment."
            >
              <svg
                className="d-none d-md-inline perci-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="89.249"
                height="35.041"
                viewBox="0 0 89.249 35.041"
              >
                <g
                  id="Group_1127"
                  data-name="Group 1127"
                  transform="translate(-98 -38)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M65.734,84.43v5a.985.985,0,0,1-.982.982h-4.91a.985.985,0,0,1-.982-.982v-5a.9.9,0,0,1,.9-.9h5.074A.9.9,0,0,1,65.734,84.43Z"
                    transform="translate(39.149 -17.649)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M159.31,55.7V50.262a.825.825,0,0,1,.8-.8h5.434a.825.825,0,0,1,.8.8V55.7a.793.793,0,0,1-.8.8h-5.434A.783.783,0,0,1,159.31,55.7Z"
                    transform="translate(20.902 -11.46)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M59.587,63.678h4.468a3.455,3.455,0,0,0,3.478-3.29A3.309,3.309,0,0,0,64.1,56.8H59.48a.622.622,0,0,1-.622-.622V50.617a.686.686,0,0,1,.687-.687h4.714A10.356,10.356,0,0,1,74.652,60.069c.082,6.236-4.067,10.491-10.319,10.491H59.652a.8.8,0,0,1-.8-.8V64.407A.737.737,0,0,1,59.587,63.678Z"
                    transform="translate(39.15 -11.545)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M81.55,82.472c-1.612-2.21-2.21-4.73-2.21-9.157s.606-6.94,2.21-9.157a10.2,10.2,0,0,1,8.4-3.977c6.588,0,10.262,4.272,10.262,11.923v2.21A1.006,1.006,0,0,1,99.2,75.319H86.173a.475.475,0,0,0-.5.507c0,3.322,1.309,4.828,4.272,4.828,2.062,0,3.273-.753,3.871-2.414a1.173,1.173,0,0,1,1.154-.851h4.28a.818.818,0,0,1,.851,1.1,9.893,9.893,0,0,1-10.164,7.946A10.189,10.189,0,0,1,81.55,82.472ZM93.718,70.295c.3,0,.507-.147.507-.45a4.224,4.224,0,0,0-4.28-4.329,4.081,4.081,0,0,0-4.378,4.329c0,.3.2.45.5.45Z"
                    transform="translate(35.428 -13.407)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M109.27,85.009V61.867a1.006,1.006,0,0,1,1.007-1.007h2.512a1.173,1.173,0,0,1,1.056.655c.3.851.5,2.111,1.358,2.111,1.563,0,2.21-2.766,7.193-2.766h1.71a1.006,1.006,0,0,1,1.007,1.007v4.779a1.006,1.006,0,0,1-1.007,1.007h-1.759c-3.773,0-6.539,1.759-6.539,5.581V85a1.006,1.006,0,0,1-1.007,1.007h-4.525A1,1,0,0,1,109.27,85.009Z"
                    transform="translate(29.992 -13.531)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M132.2,82.472c-1.612-2.21-2.21-4.73-2.21-9.157s.606-6.94,2.21-9.157a10.2,10.2,0,0,1,8.4-3.977c3.069,0,5.532.753,7.242,2.267a9.94,9.94,0,0,1,3.069,7.594.892.892,0,0,1-.851,1.007h-4.624a.866.866,0,0,1-.957-.8,4.447,4.447,0,0,0-1.056-2.717,3.994,3.994,0,0,0-2.815-.8c-1.563,0-2.512.352-3.167,1.211-.606.851-.8,2.16-.8,5.385s.2,4.525.8,5.385a3.6,3.6,0,0,0,3.167,1.211,4.22,4.22,0,0,0,2.766-.655c.606-.5.859-1.154,1.1-2.971.049-.45.4-.7.957-.7h4.624c.507,0,.851.4.851,1.007a10.228,10.228,0,0,1-2.365,6.94c-1.759,1.915-4.525,2.913-7.946,2.913A10.222,10.222,0,0,1,132.2,82.472Z"
                    transform="translate(26.228 -13.407)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M165.348,86.688h-4.926a.825.825,0,0,1-.8-.8V62.342a.825.825,0,0,1,.8-.8h4.926a.825.825,0,0,1,.8.8V85.886A.814.814,0,0,1,165.348,86.688Z"
                    transform="translate(20.845 -13.655)"
                    fill="#5f908f"
                  ></path>
                </g>
              </svg>
              <svg
                className="d-md-none"
                xmlns="http://www.w3.org/2000/svg"
                width="15.803"
                height="34.379"
                viewBox="0 0 15.803 34.379"
              >
                <g
                  id="Group_1128"
                  data-name="Group 1128"
                  transform="translate(-157 -38.385)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M65.734,84.43v5a.985.985,0,0,1-.982.982h-4.91a.985.985,0,0,1-.982-.982v-5a.9.9,0,0,1,.9-.9h5.074A.9.9,0,0,1,65.734,84.43Z"
                    transform="translate(98.148 -17.649)"
                    fill="#5f908f"
                  ></path>
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M59.587,63.678h4.468a3.455,3.455,0,0,0,3.478-3.29A3.309,3.309,0,0,0,64.1,56.8H59.48a.622.622,0,0,1-.622-.622V50.617a.686.686,0,0,1,.687-.687h4.714A10.356,10.356,0,0,1,74.652,60.069c.082,6.236-4.067,10.491-10.319,10.491H59.652a.8.8,0,0,1-.8-.8V64.407A.737.737,0,0,1,59.587,63.678Z"
                    transform="translate(98.15 -11.545)"
                    fill="#5f908f"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
