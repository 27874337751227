import { classList } from '@percihealth/react';

export default function FooterMainsite({ className }: { className?: string }) {
  return (
    <footer className={classList('footer', className)}>
      <div className="layer lightest">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-3 col-lg-4">
              <h4>Have a question?</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="https://www.percihealth.com/faq" title="FAQs">
                    FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.percihealth.com/contact"
                    title="Contact us"
                  >
                    Contact us
                  </a>
                </li>
                <li>
                  <a
                    href="https://calendly.com/perci-health/call-back"
                    target="_blank"
                    rel="noreferrer"
                    title="Schedule a free call back"
                  >
                    Schedule a free call back
                  </a>
                </li>
              </ul>
              <br />
              <div className="social-links">
                <a
                  href="https://www.instagram.com/percihealth/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                  title="Perci Instagram"
                >
                  <svg
                    viewBox="0 0 512.00096 512.00096"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0"></path>
                    <path d="m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0"></path>
                    <path d="m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0"></path>
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/percihealth/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                  title="Perci Linkedin"
                >
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m7.813 509.935h132.571v-348.613h-132.571zm30-318.613h72.571v288.612h-72.571z"></path>
                      <path d="m74.099 2.065c-40.859 0-74.099 33.241-74.099 74.099s33.24 74.1 74.099 74.1 74.1-33.241 74.1-74.1-33.242-74.099-74.1-74.099zm-44.099 74.099c0-24.316 19.782-44.099 44.099-44.099s44.1 19.782 44.1 44.099-19.783 44.1-44.1 44.1-44.099-19.784-44.099-44.1z"></path>
                      <path d="m511.679 270.79c-4.718-67.855-61.318-120.144-131.489-120.144-31.387 0-61.016 10.912-84.361 30.274v-19.6h-127.03v348.613h132.572v-190.664c0-21.488 17.481-38.97 38.97-38.97 21.487 0 38.969 17.482 38.969 38.979l.128 190.656h132.562v-238.822zm-240.308 209.145h-72.572v-288.614h67.029v42.847l24.005.138 4.46-6.924c18.85-29.265 50.961-46.735 85.897-46.735 55.836 0 100.543 42.602 101.78 96.985l.03 202.303h-72.582l-.108-160.666c0-38.03-30.94-68.97-68.969-68.97-38.03 0-68.97 30.94-68.97 68.97z"></path>
                    </g>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/PerciHealth/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                  title="Perci Facebook"
                >
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-md-3">
              <div className="footer-expand">
                <h4
                  className="js-toggle d-sm-none"
                  data-toggle="footer-locations"
                >
                  About us
                  <i className="fal fa-angle-down"></i>
                </h4>
                <h4 className="d-none d-sm-block">
                  <a
                    href="https://www.percihealth.com/about"
                    title="About Perci Health"
                  >
                    About us
                  </a>
                </h4>
                <nav id="footer-locations">
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="https://www.percihealth.com/about"
                        title="About Perci Health"
                      >
                        About Perci Health
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com#how-works"
                        className=" js-scroll "
                        data-scrollto="how-works"
                        data-offset="75"
                      >
                        How it works
                      </a>
                    </li>
                    <li>
                      <a href="/articles" title="Articles">
                        Articles
                      </a>
                    </li>
                    <li>
                      <a href="/advisory-boards" title="Our advisory boards">
                        Our advisory boards
                      </a>
                    </li>
                    <li>
                      <a href="/partners" title="Our partners">
                        Our partners
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/pricing"
                        title="Pricing"
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://percihealth.workable.com/"
                        target="_blank"
                        rel="noreferrer"
                        title="Careers"
                      >
                        Careers
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-sm-4 col-md-3">
              <div className="footer-expand">
                <h4
                  className="js-toggle d-sm-none"
                  data-toggle="footer-specialties"
                >
                  Support types
                  <i className="fal fa-angle-down"></i>
                </h4>
                <h4 className="d-none d-sm-block">
                  <a href="https://www.percihealth.com/support-types">
                    Support types
                  </a>
                </h4>
                <nav id="footer-specialties">
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-coaches"
                        title="Find a good cancer coach"
                      >
                        Cancer coaches
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-dietitians"
                        title="Find a good cancer dietitian"
                      >
                        Cancer dietitian
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-hypnotherapists"
                        title="Find a good hypnotherapist"
                      >
                        Cancer hypnotherapists
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-nurse-specialists"
                        title="Find a good nurse specialist"
                      >
                        Cancer nurse specialists
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-physiotherapists"
                        title="Find a good physiotherapist"
                      >
                        Cancer physiotherapists﻿
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-psychologists"
                        title="Find a good psychologist"
                      >
                        Cancer psychologists
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-speech-and-language-therapists"
                        title="Find a good speech and language therapist"
                      >
                        Cancer speech and language therapists
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-yoga-instructors"
                        title="Find a good yoga instructor"
                      >
                        Cancer yoga instructors
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/cancer-pilates-instructors"
                        title="Find a good pilates instructor"
                      >
                        Clinical Pilates Instructors
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/hair-and-image-advisors"
                        title="Find a good hair and image advisor"
                      >
                        Hair and image advisors
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/life-coaches"
                        title="Find a good life coach"
                      >
                        Life coach
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/lymphoedema-specialists"
                        title="Find a good lymphoedema specialist"
                      >
                        Lymphoedema specialists﻿
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/menopause-specialists"
                        title="Find a good menopause specialist"
                      >
                        Menopause specialists
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/mindfulness-and-meditation-professionals"
                        title="Find a good mindfulness and meditation professional"
                      >
                        Mindfulness and meditation professionals
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.percihealth.com/professionals/psychosexual-therapists"
                        title="Find a good psychosexual therapist"
                      >
                        Psychosexual therapists
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <div className="footer-expand">
                <h4 className="js-toggle" data-toggle="footer-professionals">
                  For professionals
                  <i className="fal fa-angle-down"></i>
                </h4>
                <nav id="footer-professionals">
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="https://www.percihealth.com/contact"
                        title="Become a Perci Professional"
                      >
                        Become a Perci Professional
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://referrals.percihealth.com"
                        title="Patient referrals"
                      >
                        Patient referrals
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row copyright">
        <div className="col-lg"></div>
        <div className="col-lg">
          ©&nbsp;2022 Perci Health. All rights reserved.
        </div>
        <div className="col-lg">
          <a
            className="mx-2 text-black"
            href="https://www.percihealth.com/terms"
            title="Terms &amp; Conditions"
          >
            Terms &amp; Conditions
          </a>
          <a
            className="mx-2 text-black"
            href="https://www.percihealth.com/privacy-policy"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
