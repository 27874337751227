import { Lead } from '@percihealth/react';
import HealixReferralForm from './HealixReferralForm';
import { HealixReferralNextSteps } from './HealixReferralNextSteps';

export default function Referral() {
  return (
    <>
      <main className="grid-outer main">
        <Lead title="Healix patient referral">
          <p>
            If you would like to refer your patients to Perci Health please fill
            out the form below.
          </p>
          <p>
            To make a referral, you will need consent from the patient and
            access to their key details.
          </p>
          <p>All details provided are encrypted and stored securely by us.</p>
        </Lead>
        <HealixReferralForm />
      </main>
      <HealixReferralNextSteps />
    </>
  );
}
