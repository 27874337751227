import { Layout } from '@packages/web-shared/components/referrals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import HealixReferral from './components/pages/HealixReferral';
import { ReferredHealixPage } from './components/pages/ReferredHealixPage';
import { ScrollToTop } from '@percihealth/react';

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Layout>
            <ScrollToTop />
            <Outlet />
          </Layout>
        }
      >
        <Route path="/" element={<HealixReferral />} />
        <Route path="referred" element={<ReferredHealixPage />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
