import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikCheckbox,
  FormikInputText,
  FormikTextArea,
  FormikDropdownSearch,
  FormikPhoneInput,
  isFormikValid,
} from '@percihealth/react';
import {
  ReferralCreateHealixSchema,
  ReferralCreateHealix,
  ReferralType,
  joinReasons,
} from '@packages/core-shared';
import { getFetchResponseErrorMessage } from '@packages/web-shared';

const joinReasonOptions = joinReasons.map((cr) => ({
  value: cr.id,
  name: cr.value,
}));

export default function ReferralForm() {
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState<string | undefined>(
    undefined,
  );

  const referralHandleOnsubmit = async (values: any, actions: any) => {
    if (!isFormikValid(formik)) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/referrals`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, type: ReferralType.healix }),
        },
      );

      if (!response.ok) {
        const errorMsg = await getFetchResponseErrorMessage(response);
        console.error(errorMsg);
        setResponseError(errorMsg);
        return;
      }

      navigate('/referred');
    } catch (error) {
      console.error(error);
      setResponseError((error as Error).message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      patient: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      referrer: {
        email: '',
      },
      details: '',
      joinReasonId: joinReasons[0].id,
      acceptForm: false,
    } as ReferralCreateHealix,
    validationSchema: ReferralCreateHealixSchema,
    onSubmit: referralHandleOnsubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormFieldset title="Patient's details">
          <FormikInputText
            name="patient.firstname"
            required
            label="Patient first name"
            value={formik.values.patient.firstname}
          />
          <FormikInputText
            name="patient.lastname"
            required
            label="Patient surname"
            value={formik.values.patient.lastname}
          />
          <FormikInputText
            name="patient.email"
            required
            label="Patient email address"
            value={formik.values.patient.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'patient.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'patient.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
          <FormikPhoneInput
            name="patient.phone"
            label="Patient telephone number"
            value={formik.values.patient.phone}
          />
          <FormikDropdownSearch
            label="Join reason"
            required
            name="joinReasonId"
            onChange={(val) =>
              formik.setFieldValue(
                'joinReasonId',
                Number(val),
                true /*validate*/,
              )
            }
            value={formik.values.joinReasonId.toString()}
            options={joinReasonOptions}
          />
        </FormFieldset>
        <FormFieldset title="Your details">
          <FormikInputText
            name="referrer.email"
            required
            label="Referrer email address"
            value={formik.values.referrer.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'referrer.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'referrer.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
        </FormFieldset>
        <FormFieldset title="Details of referral">
          <FormikTextArea
            name="details"
            className="full-width"
            required
            label="Please provide details of the referral, this will be sent to the
              Perci Health CNS"
            rows={5}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.details}
          />
        </FormFieldset>

        <FormFieldset title="">
          <div className="full-width"></div>
          <FormikCheckbox
            name="acceptForm"
            id="acceptForm"
            required
            value={formik.values.acceptForm.toString()}
            text="I confirm I have consent from the patient to refer them to Perci Health"
          />
          <div>
            <Button type="submit" fullWidth submitting={formik.isSubmitting}>
              Submit
            </Button>
          </div>
          <div className="full-width">
            {responseError && <AlertError>{responseError}</AlertError>}
          </div>
        </FormFieldset>
      </Form>
    </FormikProvider>
  );
}
