import { emailValidation } from '@percihealth/core-js';
import { InferType, boolean, object, string } from 'yup';
import { IObject } from './IObject';

export const InternalUserDataSchema = object().shape({
  email: emailValidation.required('User email is required'),
  // roles
  admin: boolean(),
  roleEditor: boolean(),
  expert: boolean(),

  firstName: string(),
  lastName: string(),
  photoUrl: string(),
});

export type InternalUserData = InferType<typeof InternalUserDataSchema>;
export type InternalUser = IObject.HasIdTimestamped<InternalUserData>;
